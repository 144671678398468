import React from "react";
import { Checkbox } from "antd";
import { fmtMsg } from "@app/util";
import { SchoolLocale } from "@app/locales/localeid";
import "./time-checkbox.less";

interface TimeCheckboxProps {
    playlistChecked: boolean;
    libraryChecked: boolean;
    handleOnChange: (name: string) => (e) => void;
}

export enum FieldName {
    playlistCheckbox = "playlistCheckbox",
    libraryCheckbox = "libraryCheckbox"
}

class TimeCheckbox extends React.Component<TimeCheckboxProps> {
    render() {
        const { handleOnChange, playlistChecked, libraryChecked } = this.props;
        return (
            <span className="time-checkbox">
                <Checkbox
                    checked={playlistChecked}
                    onChange={handleOnChange(FieldName.playlistCheckbox)}
                    disabled={!libraryChecked && playlistChecked}
                >
                    {fmtMsg({
                        id: SchoolLocale.StudentIncludePlaylistTime
                    })}
                </Checkbox>
                <Checkbox
                    checked={libraryChecked}
                    onChange={handleOnChange(FieldName.libraryCheckbox)}
                    disabled={!playlistChecked && libraryChecked}
                >
                    {fmtMsg({
                        id: SchoolLocale.StudentIncludeLibraryTime
                    })}
                </Checkbox>
            </span>
        );
    }
}

export default TimeCheckbox;
