import React, { Component } from "react";
import ReactDOM from "react-dom";
import _ from "lodash";
import moment, { Moment } from "moment";
import { Row, Col, Spin } from "antd-min";
import {Tooltip, Icon, Divider} from "antd";
import dayGridPlugin from "@fullcalendar/daygrid";
import FullCalendar from "@fullcalendar/react";
import { Icons } from "@app/style/icons";
import { isFutureDate, fmtMsg } from "@app/util/func";
import { CalendarHeader } from "./calendar-header";
import { lazyInject, TYPES, DateHelper } from "../../util/index";
import {
	IReportService,
	StudentProgressModel,
} from "../../service/school/report/index";
import { SchoolLocale } from "@app/locales/localeid";
import "@fullcalendar/core/main.css";
import "@fullcalendar/daygrid/main.css";
import "./calendar.less";
import {Speedometer} from "@app/components/progress-calendar/speedometer";

export interface ProgressCalendarProps {
	childId: string;
	showDummyData: boolean;
	startDate: string | null;
	units: any[];
	isCurrentUnit: boolean;
	schoolClassId: string;
}

enum FetchProgressType {
	calendarProgress = 1,
	percentageProgress = 2,
}

interface ProgressCalendarState {
	startDate: moment.Moment;
	endDate: moment.Moment;
	headerTitle: string;
	loading: boolean;
	eachDayData: StudentProgressModel[];
	lastThirtyDaysData: StudentProgressModel[];
	disabled: boolean;
	selectedStartDate: moment.Moment;
	playlistsData: StudentProgressModel[];
}

export class ProgressCalendar extends Component<ProgressCalendarProps, ProgressCalendarState> {
	@lazyInject(TYPES.IReportService)
	reportService: IReportService;

	constructor(props: ProgressCalendarProps) {
		super(props);
		this.state = {
			startDate: this.props.isCurrentUnit
				? moment().subtract(this.visibleDates - 1, "days")
				: this.props.startDate
				? moment(moment.utc(this.props.startDate).toDate(), "YYYY-MM-DD")
				: moment().subtract(this.visibleDates - 1, "days"),
			endDate: this.props.isCurrentUnit
				? moment().add(1, "days")
				: this.props.startDate
				? moment(moment.utc(this.props.startDate).toDate(), "YYYY-MM-DD").add(this.visibleDates, "days")
				: moment().add(1, "days"),
			headerTitle: "",
			loading: this.props.showDummyData ? false : false,
			eachDayData: this.props.showDummyData ? this.dummyData : [],
			lastThirtyDaysData: [],
			disabled: this.props.showDummyData,
			selectedStartDate: this.props.isCurrentUnit
				? moment().subtract(this.visibleDates - 1, "days")
				: this.props.startDate
				? moment(moment.utc(this.props.startDate).toDate(), "YYYY-MM-DD")
				: moment().subtract(this.visibleDates - 1, "days"),
			playlistsData: [],
		};
	}

	elRef: any = React.createRef();
	dateFormat: string = "YYYY-MM-DD";
	step: number = 28; // how many days to add/ subtract
	visibleDates: number = 28; // total visible days.
	dummyData: StudentProgressModel[] = [
		{ date: moment().toDate(), playlists: ["1"] },
		{
			date: moment()
				.add(6, "days")
				.toDate(),
			playlists: ["1", "2"],
		},
		{
			date: moment()
				.subtract(4, "days")
				.toDate(),
			playlists: ["1"],
		},
		{
			date: moment()
				.subtract(6, "days")
				.toDate(),
			playlists: ["1"],
		},
		{
			date: moment()
				.subtract(9, "days")
				.toDate(),
			playlists: ["1"],
		},
		{
			date: moment()
				.subtract(13, "days")
				.toDate(),
			playlists: ["1"],
		},
		{
			date: moment()
				.subtract(16, "days")
				.toDate(),
			playlists: ["1"],
		},
		{
			date: moment()
				.subtract(21, "days")
				.toDate(),
			playlists: ["1"],
		},
		{
			date: moment()
				.subtract(25, "days")
				.toDate(),
			playlists: ["1"],
		},
	];

	// To save all the units and their start and end Date which fall in rendered calender's startDate and endDate
	unitAndDates: any[] = [];

	componentDidMount() {
		if (!this.state.disabled) {
			// call api to get the data for dates
			this.fetchDayData();
		}

		this.unitAndDates = this.unitsDateIndicator(this.state.startDate, this.state.endDate, this.props.units);
	}

	fetchDayData = () => {
		this.fetchData(this.state.startDate, this.state.endDate, FetchProgressType.calendarProgress);
		// fetch last thirty day data, not including today
		this.fetchData(
			moment().subtract(30, "days"),
			moment().subtract(1, "days"),
			FetchProgressType.percentageProgress
		);
	}

	static getDerivedStateFromProps(props: ProgressCalendarProps, state: any) {
		if (props.showDummyData !== state.disabled) {
			return { disabled: props.showDummyData };
		}
		return null;
	}

	formatToStudentProgressModelArray(playlistlogs): StudentProgressModel[] {
		const playlogsObject = _.groupBy(
			playlistlogs.map((playlog) => {
				playlog.playedDay = DateHelper.toLocal(moment(playlog.playedAt)).format("YYYY-MM-DD");
				return playlog;
			}),
			"playedDay"
		);
		return Object.entries(playlogsObject).map((playlog) => {
			return {
				date: playlog[0] as unknown as Date,
				playlists: playlog[1],
			};
		});
	}

	// Api call to fetch data according to startDate and endDate
	fetchData = (startDate: Moment, endDate: Moment, type: FetchProgressType) => {
		const fetcher = () => {
			const params: any = {};
			params.studentId = this.props.childId;
			params.fromDate = startDate.format("YYYY-MM-DD");
			params.toDate = endDate.format("YYYY-MM-DD");

			this.reportService
				.getStudentProgress(params)
				.then((res: any[]) => {
					if (type === FetchProgressType.calendarProgress) {
						this.setState({
							loading: false,
							eachDayData: this.formatToStudentProgressModelArray(res),
						});
					} else {
						// FetchProgressType.percentageProgress
						this.setState({
							playlistsData: res,
							lastThirtyDaysData: this.formatToStudentProgressModelArray(res),
						});
					}
				})
				.catch((e: any) => {
					// show that some error has occurred.
					this.setState({ loading: false });
				});
		};

		if (type === FetchProgressType.calendarProgress) {
			this.setState({ loading: true }, () => {
				// disable loading after successfull load.
				fetcher();
			});
		} else {
			fetcher();
		}
	}

	// calculates the end date based on the date received
	calculateEndDate = (date: any) => {
		return moment(date, "YYYY-MM-DDTHH:mm:ss").add(this.step, "days");
	}

	// subtracts 'this.step' days from endDate and startDate when user clicks back button
	previousButtonClicked = (e: any) => {
		const endDate = moment(this.state.endDate).subtract(this.step, "days");
		const startDate = moment(this.state.startDate).subtract(this.step, "days");
		this.setState(
			{
				endDate,
				startDate,
				selectedStartDate: moment(startDate),
			},
			() => {
				this.fetchData(this.state.startDate, this.state.endDate, FetchProgressType.calendarProgress);
				this.unitAndDates = this.unitsDateIndicator(this.state.startDate, this.state.endDate, this.props.units);
			}
		);
	}

	/* Sets the startDate and endDate when user clicks forward button. */
	forwardButtonClicked = (e: any) => {
		const currentStartDate = _.cloneDeep(this.state.startDate);
		const currentEndDate = _.cloneDeep(this.state.endDate);
		// To check if the today's date is in range of current (startDate + 28) days and current (endDate + 28) days
		const IsTodayInRange = moment().isBetween(
			currentStartDate.add(this.step, "days"),
			currentEndDate.add(this.step, "days")
		);

		// Checks if today's date is before current (endDate+28) days and IsTodayInRange.
		// If true sets endDate as Today's date and startDate as (today - 28) days.
		if (moment().isBefore(currentEndDate.add(this.step, "days")) && IsTodayInRange) {
			// Adds one day to Today's date since one less day is shown on progress chart.
			const endDate = moment().add(1, "days");
			const startDate = moment().subtract(this.step - 1, "days");
			this.setState({ endDate, startDate, selectedStartDate: moment(startDate) }, () => {
				this.fetchData(this.state.startDate, this.state.endDate, FetchProgressType.calendarProgress);
				this.unitAndDates = this.unitsDateIndicator(this.state.startDate, this.state.endDate, this.props.units);
			});
		} else {
			const endDate = moment(this.state.endDate).add(this.step, "days");
			const startDate = moment(this.state.startDate).add(this.step, "days");
			this.setState({ endDate, startDate, selectedStartDate: moment(startDate) }, () => {
				this.fetchData(this.state.startDate, this.state.endDate, FetchProgressType.calendarProgress);
				this.unitAndDates = this.unitsDateIndicator(this.state.startDate, this.state.endDate, this.props.units);
			});
		}
	}

	// Returns date with unit start or unit end for which indicator needs to be displayed on Calender.
	unitsDateIndicator = (startDate: moment.Moment, endDate: moment.Moment, unitsData: any[]) => {
		const unitAndDates = unitsData.reduce((acc, data) => {
			const isStartDateInRange = moment(data.startDate, "YYYY-MM-DD").isBetween(
				startDate,
				endDate,
				undefined,
				"[]"
			);
			const isEndDateInRange = moment(data.endDate, "YYYY-MM-DD").isBetween(startDate, endDate, undefined, "[)");
			if (isStartDateInRange) {
				acc.push({ unit: data.unit, startDate: moment(data.startDate) });
			}
			if (isEndDateInRange) {
				acc.push({ unit: data.unit, endDate: moment(data.endDate) });
			}

			return acc;
		}, []);

		return unitAndDates;
	}

	// To Check if the give date is same as any of the units startDate or endDate or both.
	// returns unit start/ unit end or both to be shown as tooltiptext of unit indicator.
	isTodayStartDateOrEndDate = (date: Moment) => {
		let textToReturn = "";
		const startDate = this.unitAndDates.find(
			(unitDate) => unitDate.startDate && moment(unitDate.startDate).isSame(moment(date))
		);
		const endDate = this.unitAndDates.find(
			(unitDate) => unitDate.endDate && moment(unitDate.endDate).isSame(moment(date))
		);
		if (startDate) {
			textToReturn = `U${startDate.unit} Start`;
		}
		if (endDate) {
			textToReturn = `U${endDate.unit} End`;
		}
		return textToReturn;
	}

	// used to manipulate the inner html of a day
	// it only sets the html (React node can't be used)
	dayRender = (v: any) => {
		// const smileIcon = this.weekIcon(v.date);
		const smileIcon = <img src={Icons.SmileyThree} className="gl gl-jingle-px smileyicon" alt="" />;
		const isDatePresent = this.findSpecificDateInResponse(v.date);
		const unitInfo = this.isTodayStartDateOrEndDate(v.date);
		const content = (
			<div>
				<div className="cal__hide-icon"></div>
				<div className="cal__calendar-smile-icon">
					{isDatePresent > -1 && this.state.eachDayData[isDatePresent].playlists.length > 0 ? smileIcon : ""}
				</div>
				{unitInfo ? (
					<>
						<div className="cal__calender-info">{unitInfo}</div>
						<div className="cal__calender-info-icon">
							<Tooltip title={unitInfo}>
								<Icon type="info-circle"></Icon>
							</Tooltip>
						</div>
					</>
				) : (
					""
				)}
				{
					<div className="cal__align-item">
						<span className="cal__count-number">
							{(isDatePresent > -1 && this.state.eachDayData[isDatePresent].playlists.length > 0) ?
								this.state.eachDayData[isDatePresent].playlists.length : ""}
						</span>
					</div>
				}
			</div>
		);
		ReactDOM.render(content, v.el);
	}

	// finds the index of a specific date in the response of API
	findSpecificDateInResponse = (date: any) => {
		return this.state.eachDayData.findIndex((day: any) => moment(day.date).isSame(moment(date), "day"));
	}

	// Use to fetch the title from the 'Full Calendar' to show in our custom header
	datesRender = (v: any) => {
		if (v.view.title !== this.state.headerTitle) {
			this.setState({ headerTitle: v.view.title });
		}
	}

	// Disables forward button if the current date falls in visible dates and is in last week of visible dates.
	disableForwardButton = () => {
		// If the (endDate) is greater than Today's date then disable forward button.
		if (moment(this.state.endDate).isAfter(moment())) {
			return true;
		}
		return false;
	}

	// Sets the startDate and endDate when a date is selected by user from DatePicker.
	// Sets the selectedStartDate for the date DatePicker.
	handleSelectedStartDateChange = (date: Date): void => {
		const isValidDay = date && typeof date !== "undefined" && !isFutureDate(date);
		if (isValidDay) {
			this.setState(
				{
					selectedStartDate: moment(date),
					startDate: moment(date),
					endDate: this.calculateEndDate(date),
				},
				() => {
					this.fetchData(this.state.startDate, this.state.endDate, FetchProgressType.calendarProgress);
					this.unitAndDates = this.unitsDateIndicator(
						this.state.startDate,
						this.state.endDate,
						this.props.units
					);
				}
			);
		}
	}

	render() {
		const endDate = this.state.endDate;
		const startDate = this.state.startDate;
		return (
			<>
				<Speedometer lastThirtyDayData={this.state.playlistsData}/>
				<Row className="cal cal__calendar-note-container ">
					<Col span={24} className="cal__calendar-wrapper">
						<p className="note">{fmtMsg({ id: SchoolLocale.StudentDetailNote })}</p>
						<Divider />
					</Col>
				</Row>
				<Row className="cal cal__calendar-container">
					<Col span={24} className="cal__calendar-wrapper">
						<CalendarHeader
							onClickBack={this.previousButtonClicked}
							onClickForward={this.forwardButtonClicked}
							title={this.state.headerTitle}
							disableForwardButton={this.disableForwardButton()}
							lastThirtyDayData={
								this.props.showDummyData ? this.dummyData.slice(1) : this.state.lastThirtyDaysData
							}
							selectedStartDate={this.state.selectedStartDate}
							handleSelectedStartDateChange={this.handleSelectedStartDateChange}
							disablePopperFlip={true}
						/>
						{this.state.loading ? (
							<div className="gs-center-loader">
								<Spin size="large" />
							</div>
						) : (
							<FullCalendar
								defaultView="dayGrid"
								plugins={[dayGridPlugin]}
								ref={this.elRef}
								firstDay={startDate.day()}
								fixedWeekCount={true}
								nowIndicator={false}
								visibleRange={{
									start: startDate.format(this.dateFormat),
									end: endDate.format(this.dateFormat),
								}}
								datesRender={this.datesRender}
								dayRender={this.dayRender}
								header={false}
								aspectRatio={2}
								columnHeaderText="x"
								timeZone="local"
							/>
						)}
						<div className="cal__legend">
							<img src={Icons.SmileFace}  alt=""/>
							{fmtMsg({ id: SchoolLocale.ReportCalLegend })}
						</div>
					</Col>
				</Row>
			</>
		);
	}
}
