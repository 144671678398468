import * as React from "react";
import { FormattedMessage } from "react-intl";
import moment from 'moment'
import { Form, Input, Drawer, Button, Col, Row, Avatar, message, Select, DatePicker  } from "antd-min";
import { GLGlobal, GLForm, GLFormComponentProps, LanguageDateFormat } from "gl-commonui";
import { IStudentService } from "@app/service/school/student";
import { lazyInject, TYPES, fmtMsg, DateHelper, isInternalUser } from "@app/util";
import { SchoolLocale, GSAdminLocale } from "@app/locales/localeid";
import EditAvatar from "./edit-avatar";
import { Gender } from "@app/util/enum";
import { MaleIcon, FemaleIcon } from "@app/components/svgicon";
import { StudentDetailsModelProps } from "@app/service/school/student-registration";
import './edit-child.less';

interface EditChildProps {
    visible: boolean;
    child?: any;
    updateHandler?: (child) => void;
    closeHandler?: (visible) => void;
}
interface EditChildState {
    updating: boolean;
    forceRerenderAvatar: number
}

@GLForm.create()
export class EditChildInfo extends React.Component<EditChildProps & GLFormComponentProps, EditChildState> {

    @lazyInject(TYPES.IStudentService)
    studentService: IStudentService;
    studentAvatar: string = '';
    updateAvatar = false;

    constructor(props: EditChildProps) {
        super(props);
        this.state = {
            updating: false,
            forceRerenderAvatar: 1
        };
    }

    submitHandler = (e): void => {
        const { child, form, updateHandler } = this.props;
        e.preventDefault();
        form.validateFields((err, values) => {
            if (err) { return; }
            this.setState({ updating: true });
            const gender = values.gender == Gender.None ? null : (values.gender == Gender.Male ? true : false);
            const birthday =  values.birthday && moment(values.birthday).format(LanguageDateFormat[
                GLGlobal.intl.locale
            ])
            values.birthday = birthday;
            values.gender = gender;
            values.avatar = this.studentAvatar;
            values.updateAvatar = this.updateAvatar;
            this.studentService.merge(values, {id: child.id}).then(res => {
                this.setState({ updating: false });
                updateHandler(values);
                this.close(null);
            }).catch (error => {
                this.setState({ updating: false });
                message.error(fmtMsg({ id: SchoolLocale.StudentEditUpdateFailed }));
            });
        });
    }

    close = (e): void => {
        const { form, closeHandler } = this.props;
        e && form.resetFields();
        closeHandler(false);
    }

    closeEditForm = (e): void => {
        this.close(e);
        this.setState({
            forceRerenderAvatar: this.state.forceRerenderAvatar + 1
        });
    }

    setNewAvatar = (img: string) => {
        this.studentAvatar = img;
        this.updateAvatar = true;
    }

    render() {
        const { visible, child, form } = this.props;
        const { getFieldDecorator } = form;
        const { avatarUrl, name, englishName, gender, birthday } = child || {};
        const defaultGenderValue = gender == null ? Gender.None : gender ? Gender.Male : Gender.Female;
        return (
            <div>
                <Drawer
                    className="drawer-editchild"
                    maskClosable={false}
                    title={englishName}//{fmtMsg({ id: SchoolLocale.StudentEditTitle })}
                    width={300}
                    onClose={this.closeEditForm}
                    visible={visible}
                >
                    <div className="drawer-editchild-content">
                        <Row>
                            <EditAvatar key={this.state.forceRerenderAvatar} avatar={avatarUrl} setImageUrl={this.setNewAvatar}/>
                        </Row>
                        <GLForm form={form} layout="vertical">
                            <Row>
                                <Col span={24}>
                                    <Form.Item label={fmtMsg({ id: SchoolLocale.StudentName })}>
                                        {getFieldDecorator("name", {
                                            initialValue: name,
                                            rules: [
                                                {
                                                    required: true,
                                                    whitespace: true,
                                                    message: fmtMsg({ id: SchoolLocale.StudentEditEnterName })
                                                }
                                            ],
                                        })(
                                            <Input
                                                placeholder={fmtMsg({ id: SchoolLocale.StudentEditEnterName })}
                                                maxLength={50}
                                            />
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <Form.Item label={GLGlobal.intl.formatMessage({ id: SchoolLocale.StudentEnName })}>
                                        {getFieldDecorator("englishName", {
                                            initialValue: englishName,
                                            rules: [
                                                {
                                                    required: true,
                                                    whitespace: true,
                                                    message: fmtMsg({ id: SchoolLocale.StudentEditEnterEnglishName })
                                                }
                                            ],
                                        })(
                                            <Input
                                                placeholder={fmtMsg({ id: SchoolLocale.StudentEditEnterEnglishName })}
                                                maxLength={50}
                                            />
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <Form.Item label={fmtMsg({ id: SchoolLocale.StudentGender })}>
                                    {getFieldDecorator(StudentDetailsModelProps.gender, {
                                                initialValue: defaultGenderValue
                                            })(
                                                <Select dropdownClassName="gender-select">
                                                    <Select.Option
                                                        key={Gender.None}
                                                        value={Gender.None}
                                                        className={'drawer-editchild-content__option'}
                                                    />
                                                    <Select.Option
                                                        key={Gender.Male}
                                                        value={Gender.Male}
                                                    >
                                                        {fmtMsg({
                                                            id:
                                                                SchoolLocale.StudentRegistrationStudentMale
                                                        })}
                                                    </Select.Option>
                                                    <Select.Option
                                                        key={Gender.Female}
                                                        value={Gender.Female}
                                                    >
                                                        {fmtMsg({
                                                            id:
                                                                SchoolLocale.StudentRegistrationStudentFemale
                                                        })}
                                                    </Select.Option>
                                                </Select>
                                            )}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <Form.Item
                                        label={fmtMsg({
                                            id: SchoolLocale.StudetnBirthday
                                        })}
                                    > 
                                    {getFieldDecorator(StudentDetailsModelProps.birthday, {
                                        initialValue: DateHelper.formatDate2Local(
                                            birthday
                                        )
                                            ? moment(
                                                    DateHelper.formatDate2Local(
                                                        birthday
                                                    ),
                                                    LanguageDateFormat[
                                                        GLGlobal.intl
                                                            .locale
                                                    ]
                                                )
                                            : null
                                    })(
                                        <DatePicker
                                            format={
                                                LanguageDateFormat[
                                                    GLGlobal.intl.locale
                                                ]
                                            }
                                        />
                                    )}
                                    </Form.Item>
                                </Col>
                            </Row>
                        </GLForm>
                    </div>
                    <div className="drawer-editchild-footer">
                        <Button
                            disabled={this.state.updating}
                            onClick={this.closeEditForm}
                        ><FormattedMessage id={GSAdminLocale.ButtonCancel} /></Button>
                        <Button
                            loading={this.state.updating}
                            onClick={this.submitHandler.bind(this)}
                            type="primary"
                        ><FormattedMessage id={GSAdminLocale.ButtonSave} /></Button>
                    </div>
                </Drawer>
            </div>
        );
    }
}
