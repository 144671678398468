import React, {useState} from 'react';
import {Icon, message, Upload} from "antd-min";
import {SchoolLocale} from "@app/locales/localeid";
import {fmtMsg} from "@app/util";
import {GLGlobal} from "gl-commonui";

export interface EditAvatarProps {
    avatar: string;
    setImageUrl?: (newImageUrl: string) => void;
}

const EditAvatar = (props: EditAvatarProps) => {

    const [imageLoading, setImageLoading] = useState(false);
    const [imageBase64Url, setImageBase64Url] = useState('');
    const [isImageDeleted, setImageDeleted] = useState(false);

    const handleChange = (info) => {
        if (info.file.status === "uploading") {
            setImageLoading(true);
            return;
        }

        if (info.file.status === "done") {
            setImageDeleted(false);
            const reader = new FileReader();
            reader.addEventListener(
                "load",
                () => {
                    setImageBase64Url(reader.result.toString());
                    resizeImage(reader.result.toString());
                    setImageLoading(false);
                },
                false
            );

            reader.readAsDataURL(info.file.originFileObj);
        }
    };

    function resizeImage(imageBase64Code) {
        const img = document.createElement("img");
        img.src = imageBase64Code;
        img.onload = (ev => {
            const newWidth = 200;
            const newHeight = (200 * img.height) / img.width;
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");
            ctx.canvas.width = newWidth;
            ctx.canvas.height = newHeight;
            ctx.drawImage(img, 0, 0, newWidth, newHeight);
            const compressedImg = canvas.toDataURL();
            props.setImageUrl(compressedImg.slice(compressedImg.indexOf("base64,") + 7));
        });
    }

    const dummyRequest = ({file, onSuccess}) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 0);
    };

    const deleteImage = () => {
        props.setImageUrl('');
        setImageDeleted(true);
        setImageBase64Url('');
        setImageLoading(false);
    }

    const beforeUpload = (file) => {
        const isAllowedType = file.type === "image/jpeg" || file.type === "image/jpg" || file.type === "image/png";
        if (!isAllowedType) {
            message.error(GLGlobal.intl.formatMessage({id: SchoolLocale.StudentUploadAvatarImageType}));
        }
        const isSizeAllowed = file.size / 1024 / 1024 < 2;
        if (!isSizeAllowed) {
            message.error(GLGlobal.intl.formatMessage({id: SchoolLocale.StudentUploadAvatarMaxSize}));
        }
        return isAllowedType && isSizeAllowed;
    }

    const preventClickingOnUpload = (e: any) => {
        e.stopPropagation();
    }

    const hasImage = (): boolean => {
        if (isImageDeleted) {
            return false;
        }
        return !imageLoading && imageBase64Url && imageBase64Url.length > 0 || props.avatar && props.avatar.length > 0;
    }

    const getCurrentImageUrl = (): string => {
        if (isImageDeleted) {
            return "";
        }
        if (!imageLoading && imageBase64Url) {
            return imageBase64Url;
        }
        return props.avatar;
    }

    const renderImageBlock = () => {
        if (hasImage()) {
            return (
                <span className={"edit-child__image"}>
						<img src={getCurrentImageUrl()} style={{width: "100px", height: "100px"}}/>
				</span>
            )
        }

        return <div>
            <Icon type={imageLoading ? "loading" : "plus"}/>
            {!imageLoading && (
                <div className="ant-upload-text">
                    {fmtMsg({id: SchoolLocale.StudentUploadAvatar})}
                </div>
            )}
        </div>
    }

    return (
        <Upload
            disabled={imageLoading}
            accept={"image/jpg,image/png,image/jpeg"}
            name="avatar"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            action=""
            beforeUpload={beforeUpload}
            onChange={handleChange}
            customRequest={dummyRequest}
        >
            {hasImage() && (
                <span className="avatar-uploader-actions" onClick={preventClickingOnUpload}>
						 <Icon type="delete" onClick={deleteImage}/>
					</span>
            )}
            {renderImageBlock()}
        </Upload>
    )
}

export default EditAvatar;