import * as React from "react";
import { Component, useState, useEffect, useRef } from "react";
import { RouteComponentProps } from "react-router-dom";
import { SchoolLocale, GSAdminLocale } from "@app/locales/localeid";
import { withRouter, GLGlobal, connect, GLAction, GLUtil, Role } from "gl-commonui";
import { StateType } from "@app/states";
import {
    lazyInject,
    TYPES,
    DateHelper,
    GSSchoolAction,
    fmtMsg,
    ClassCurriculumType,
} from "@app/util/index";
import {Icon, Modal, notification, Skeleton, Row, Col, Card } from "antd-min";
import { GLIcon } from "@app/components";
import { EditChildInfo } from "./edit-child";
import { reloadBreadcrumbs } from "@app/states/resource";
import { PathConfig, AdminPathConfig } from "@app/config/pathconfig";
import { PathConfig as CommonPath } from "gl-commonui";
import { isUndefined } from "util";
import { useService } from "@app/hooks";
import { IStatisticsService } from "@app/service/school/statistic";
import { IStudentService } from "@app/service/school/student";
import { StudentActivityReport } from "./student-activity-report";
import { get } from "@app/states/school/class";
import { ProgressCalendar } from "@app/components/progress-calendar/progress-calendar";
import moment from "moment";
import {ChildModel} from "@app/service/school/parent";
import {ChildSubscriptionType} from "@app/service/class";
interface StudentReportPageProps {
    langLoaded?: string;
    allowSetTeacherContactParent?: boolean;
    allowTeacherMailToParent?: boolean;
    reloadBreadcrumbs?: () => void;
    get: (d) => void;
    userRoles: string[];
}

@withRouter
@connect(
    ({
        intl: { langLoaded },
        school: {
            current: { allowSetTeacherContactParent, allowTeacherMailToParent },
            userRoles
        }
    }: StateType) => ({
        langLoaded,
        allowSetTeacherContactParent,
        allowTeacherMailToParent,
        userRoles
    }),
    {
        reloadBreadcrumbs,
        get
    }
)
export class StudentReportPage extends Component<
    RouteComponentProps<any> & StudentReportPageProps,
    { parent; studentWithAvatar; studentEditorVisible; student; disabled: boolean, isShow: boolean }
> {
    child: ChildModel = null;
    @lazyInject(TYPES.IStudentService)
    studentService: any;
    @lazyInject(TYPES.IUserService)
    userService: any;
    @lazyInject(TYPES.ISchoolClassService)
    schoolClassService: any;
    report: any = {};
    dailyChart;
    weeklyChart;
    nameLocation = "end";

    constructor(props) {
        super(props);
        this.state = {
            student: {},
            studentEditorVisible: false,
            studentWithAvatar: {},
            parent: {},
            disabled: false,
            isShow: false,
        };
    }

    componentWillMount() {
        this.studentService
            .get(this.props.match.params.studentId)
            .then(data => {
                this.setState({ student: data });
            });
    }

    componentDidMount() {
        this.getParentMailTitle();
        // call to get the schoolcalss info for showing item (registerstudents on breadcrumb menu)
        const {
            get,
            match: { params }
        } = this.props;
        if (params.schoolId && params.classId) {
            get({ schoolId: params.schoolId, id: params.classId });
        }
        this.setChildInfo();
    }
    componentWillReceiveProps(nextProps) {}

    setChildInfo() {
        const that = this;
        return new Promise(() => {
            this.studentService.getStudent({id: this.props.match.params.studentId}).then((data: ChildModel) => {
                that.child = data;
                if (
                    that.child &&
                    (that.child.unit || that.child.studentUnits.length) &&
                    that.child.isSeeProgressVisible &&
                    that.child.isSeeProgressVisible === true
                ) {
                    let isDisabled = !(
                        that.child.studentSubscriptionType === ChildSubscriptionType.Digital ||
                        that.child.studentSubscriptionType === ChildSubscriptionType.Dual
                    );

                    if (this.child.curriculumType === ClassCurriculumType.LittleSeedLicense) {
                        isDisabled = false;
                    }
                    that.setState({ disabled: isDisabled, isShow: true });
                } else {
                    that.setState({ isShow: false });
                }
            });
        });
    }


    getStudent() {
        this.studentService.get(this.state.student.id).then(data => {
            this.setState({
                studentWithAvatar: { ...data, avatarUrl: data.avatar }
            });
        });
    }
    getParentMailTitle() {
        this.studentService
            .getParent({ id: this.props.match.params.studentId })
            .then(data => {
                if (data) {
                    this.userService
                        .getItemsBy({ ids: data })
                        .then(({ data }) => {
                            if (data && data.length) {
                                this.setState({ parent: data[0] });
                            } else {
                                this.setState({ parent: {} });
                            }
                        });
                } else {
                    this.setState({ parent: {} });
                }
            });
    }
    setStudentEditorVisible(visible) {
        visible && this.getStudent();
        this.setState({
            studentEditorVisible: visible
        });
    }
    updateStudentName(child) {
        const { reloadBreadcrumbs } = this.props;
        if (child.englishName != this.state.student.englishName) {
            reloadBreadcrumbs();
            this.setState({
                student: {
                    ...this.state.student,
                    englishName: child.englishName
                }
            });
        }
    }
    removeStudent() {
        this.triggerConfirmModal(this);
    }
    triggerConfirmModal(self) {
        Modal.confirm({
            title: fmtMsg({
                id: SchoolLocale.StudentRegistrationRemoveConfirmationTitle
            }),
            content: fmtMsg({
                id: SchoolLocale.StudentRegistrationRemoveConfirmationMessage
            }),
            okText: fmtMsg({ id: GSAdminLocale.ModelButtonYes }),
            okType: "danger",
            cancelText: fmtMsg({ id: GSAdminLocale.ModelButtonNo }),
            onOk() {
                self.removeStudentFromClass();
            },
            onCancel() {}
        });
    }
    removeStudentFromClass() {
        let params = this.props.match.params;
        this.schoolClassService
            .deleteDeactivatedStudent(params.classId, params.studentId)
            .then(data => {
                this.props.history.push({
                    pathname: GLUtil.pathStringify(PathConfig.Students, {
                        regionId: params.regionId,
                        schoolId: params.schoolId,
                        campusId: params.campusId,
                        classId: params.classId
                    })
                });
            })
            .catch(er => {
                notification.error({
                    message: fmtMsg({
                        id: SchoolLocale.StudentRegistrationRemoveErrorMessage
                    }),
                    className: "tsc-notif"
                });
            });
    }


    redirectToEditUserPage = () => {
        this.props.history.push({
            pathname: GLUtil.pathStringify(AdminPathConfig.UserEdit, { userId:  this.state.parent.id })
        });
    }

    renderCalendar = () => {
        const startDateInQueryParam = GLUtil.queryParse(this.props.location.search).startDate;
        const isCurrentUnitInQueryParam = GLUtil.queryParse(this.props.location.search).isCurrentUnit;

        // Checks if the date in query params is valid or not.
        const startDateFromQueryParam = Object.is(new Date(startDateInQueryParam).getTime(), NaN)
            ? null
            : moment(moment.utc(startDateInQueryParam, "YYYY-MM-DDTHH:mm:ss").toDate()).format("YYYY-MM-DD");

        // Convert the value query params to true or false( from string to boolean)
        const isCurrentUnitFromQueryParam = isCurrentUnitInQueryParam === "true" ? true : isCurrentUnitInQueryParam === "false" ? false : null;

        const allUnitsData = [...this.child.studentUnits];

        /*
            Convert the startDate and endDate at the moment format of "YYYY-MM-DD"
            so that it matches the startDate and endDate format on ProgressCalender.
        */
        allUnitsData.forEach((data) => {
            data.startDate = moment(moment.utc(data.startDate, "YYYY-MM-DDTHH:mm:ss").toDate()).format("YYYY-MM-DD");
            data.endDate = moment(moment.utc(data.endDate, "YYYY-MM-DDTHH:mm:ss").toDate()).format("YYYY-MM-DD");
        });

        // checking for chart ref because there is a condition in ComponentDidMount,
        // which is causing 'ProgressCalendar' to mount two times.
        // So, if in future we need to add new components then check for below condition
        // to prevent mounting many times.
        return <ProgressCalendar
            childId={this.child.id}
            showDummyData={this.state.disabled}
            startDate={startDateFromQueryParam}
            units={allUnitsData}
            isCurrentUnit={isCurrentUnitFromQueryParam}
            schoolClassId={this.child.schoolClassId}
        ></ProgressCalendar>;
    }

    render() {
        const parent = this.state.parent;
        const {
            allowSetTeacherContactParent,
            allowTeacherMailToParent,
            match: { params },
            userRoles
        } = this.props;
        const studentWithAvatar = this.state.studentWithAvatar;
        const studentEditorVisible = this.state.studentEditorVisible;
        return (
            <div className="content-layout student-report">
                <ReportTitle
                    title={this.state.student.englishName}
                    parentId={parent.id}
                    redirectToEditUserPage={this.redirectToEditUserPage}
                    parentName={parent.name}
                    parentEmail={parent.email}
                    parentPhone={parent.phone}
                    allowTeacherMailToParent={
                        allowSetTeacherContactParent && allowTeacherMailToParent
                    }
                    setStudentEditorVisible={this.setStudentEditorVisible.bind(
                        this
                    )}
                    removeDeactivedStudent={this.removeStudent.bind(this)}
                    isActivated={this.state.student.isActivated}
                    params={params}
                    userRoles={userRoles}
                />
                <div className="page-content">
                    {this.state.isShow && this.child && this.child.curriculumType == ClassCurriculumType.GrapeSeedLicense && (
                        <Card bordered={false}>
                            <div className="gs-container" style={{ padding: 0 }}>{this.renderCalendar()}</div>
                        </Card>
                    )}
                    <StudentActivityReport studentId={params.studentId}></StudentActivityReport>
                    <EditChildInfo
                        child={studentWithAvatar}
                        closeHandler={this.setStudentEditorVisible.bind(this)}
                        updateHandler={this.updateStudentName.bind(this)}
                        visible={studentEditorVisible}
                    />
                      <p className="student-detail-note">{fmtMsg({ id: SchoolLocale.StudentDetailNotePlaytime })}</p>
                </div>
            </div>
        );
    }
}

const ReportTitle = ({
    title,
    parentName,
    parentEmail,
    parentPhone,
    parentId,
    allowTeacherMailToParent,
    setStudentEditorVisible,
    removeDeactivedStudent,
    isActivated,
    params,
    userRoles,
    redirectToEditUserPage
}) => {
    const [loadingUnit, setLoadingUnit] = useState(true);
    const [loadingGPeriod, setLoadingGPeriod] = useState(true);
    const info = useRef({ unit: null, gracePeriodEnd: null });

    const repService = useService<IStatisticsService>(TYPES.IStatisticsService);
    const studentService = useService<IStudentService>(TYPES.IStudentService);

    useEffect(() => {
        const unitAction = repService.getLastPlayedUnit(params.studentId);
        const studentAction = studentService.getStudentGracePeriodEnd(
            params.studentId
        );
        unitAction
            .then(unit => {
                if (unit) {
                    info.current = { ...info.current, unit };
                }
                setLoadingUnit(false);
            })
            .catch(() => setLoadingUnit(false));
        studentAction
            .then(period => {
                if (period) {
                    info.current = { ...info.current, gracePeriodEnd: period };
                }
                setLoadingGPeriod(false);
            })
            .catch(() => setLoadingGPeriod(false));
    }, []);

    title =
        title || GLGlobal.intl.formatMessage({ id: SchoolLocale.ReportTitle });

    const skel = (
        <Skeleton
            active
            paragraph={{
                rows: 1,
                width: 100,
                className: "student-report__ant-para"
            }}
            title={false}
            className="student-report__skel"
        ></Skeleton>
    );
    const unit = info.current.unit ? info.current.unit : null;
    const gracePeriod = info.current.gracePeriodEnd
        ? DateHelper.formatDate2Local(info.current.gracePeriodEnd)
        : null;

    const unitText = fmtMsg({ id: SchoolLocale.StudentLastUnit });
    const graceText = fmtMsg({ id: SchoolLocale.StudentGracePeriod });
    const showUnitGraceRow =
        loadingUnit || unit || loadingGPeriod || gracePeriod;

    const renderParentName = React.useMemo(() => {
        const rolesShowAsLink = [Role.SystemAdmin, Role.GlobalHead, Role.RegionAdmin, Role.AccountManager]
        if (
            userRoles && userRoles.some(
                userRole =>
                    rolesShowAsLink.findIndex(
                        role => role === Role[userRole as keyof typeof Role]
                    ) > -1
            )
        ) {
            return (
                <span style={{color: '#1ea047', cursor: 'pointer'}} onClick={redirectToEditUserPage}>
                    {parentName}
                </span>
            )
        }
        return (
            <span style={{fontWeight: 400}}>
                 {parentName}
            </span>
        )
    }, [userRoles, parentName])

    return (
        <div className="edit-list-title">
            <div className="edit-list-title-studentname">
                <h1 title={title}>{title}</h1>
                <GLAction action={GSSchoolAction.EditStudentName}>
                    <GLIcon
                        className="edit-list-title-studentname__edit"
                        type="edit"
                        labelId={SchoolLocale.IconEdit}
                        onClick={() => setStudentEditorVisible(true)}
                    />
                    {!isUndefined(isActivated) && !isActivated && (
                        <GLIcon
                            className="edit-list-title-studentname__delete"
                            type="delete"
                            labelId={SchoolLocale.IconDelete}
                            onClick={() => removeDeactivedStudent()}
                        />
                    )}
                </GLAction>
            </div>
            <Row>
                <Col className="edit-list-title-parentname">
                    <h3 title={parentName}>
                        {allowTeacherMailToParent ? (
                            <div className="edit-list-title-parentname__content">
                                {parentName && <div>
                                    <span style={{ marginRight: 5 }}>
                                        {fmtMsg({
                                            id:
                                                SchoolLocale.StudentReportLabelParent
                                        })}
                                    </span>

                                    {renderParentName}
                                </div>}
                                {parentPhone && (
                                    <div>
                                        <span>
                                            {fmtMsg({
                                                id:
                                                    SchoolLocale.StudentReportLabelParentPhone
                                            })}{" "}
                                        </span>
                                        <span style={{ fontWeight: 400 }}>
                                            {parentPhone}
                                        </span>
                                    </div>
                                )}
                                {parentEmail && (
                                    <div>
                                        <a
                                            style={{
                                                marginRight: 10,
                                                display: "inline-block",
                                                paddingTop: 1
                                            }}
                                            href={`mailto:${parentEmail}`}
                                        >
                                            <Icon type="mail" />
                                        </a>
                                        <a
                                            style={{ fontWeight: 400 }}
                                            href={`mailto:${parentEmail}`}
                                        >
                                            {parentEmail}
                                        </a>
                                    </div>
                                )}
                            </div>
                        ) : (
                            <span>{parentName}</span>
                        )}
                    </h3>
                    {showUnitGraceRow && (loadingUnit || unit) && (
                        <>
                            {!loadingUnit ? (
                                <h3>
                                    {unitText}: {unit}
                                </h3>
                            ) : (
                                <>
                                    <h3>{unitText}: </h3>{skel}
                                </>
                            )}
                        </>
                    )}
                    {showUnitGraceRow && (loadingGPeriod || gracePeriod) && (
                        <>
                            {!loadingGPeriod ? (
                                <h3>
                                    {graceText}: {gracePeriod}
                                </h3>
                            ) : (
                                <>
                                    <h3>{graceText}: </h3>{skel}
                                </>
                            )}
                        </>
                    )}
                </Col>
            </Row>
        </div>
    );
};
