import React from "react";
import { Tooltip, Icon } from "antd-min";
import { GLGlobal } from "gl-commonui";
import { GSSchoolAction } from "@app/util";
import { SchoolLocale } from "@app/locales/localeid";
import { FormattedMessage } from "react-intl";
import { Alert } from "antd-min";

export const ClassInternalNotes = ({ notes }) => {
    if (
        !notes ||
        notes.trim() === "" ||
        !GLGlobal.isActionValid(GSSchoolAction.EditSchoolInternalNotes)
    ) {
        return null;
    }
    return (
        <Alert
            message={
                <div>
                    <b>
                        <FormattedMessage
                            id={SchoolLocale.ClassInternalNotes}
                        />

                        <Tooltip
                            title={
                                <FormattedMessage
                                    id={
                                        SchoolLocale.ClassInternalNotesPlaceholder
                                    }
                                />
                            }
                            overlayClassName="class-internal-notes-popover"
                            placement="topLeft"
                            key="class-internal-notes"
                        >
                            <Icon
                                type="info-circle"
                                className="contact-info-icon"
                            />
                        </Tooltip>
                        <span> : </span>
                    </b>
                    <span>&nbsp;{notes.trim()}</span>
                </div>
            }
            type="success"
        ></Alert>
    );
};
