import React from "react";
import ReactDOM from "react-dom";
import { GLGlobal } from "gl-commonui";
import { Button } from "antd-min";
import { SchoolLocale } from "@app/locales/localeid";
import { IReportService, PlayInfo, ReportResponseV2, DigitalStudentRepModeParam } from "@app/service/school/report";
import { fmtMsg, getWidth, lazyInject, MonthType, TYPES } from "@app/util";
import "./student-activity-report.less";
import TimeCheckbox, {FieldName as TimeCheckboxFieldName} from "@app/components/time-checkbox";


interface StudentActivityReportState {
	selectedReportType: ChartReportType;
	width?: number;
	isDataReady: boolean;
	recentLogs: PlayInfo[];
	libraryChecked: boolean,
	playlistChecked: boolean,
}

interface StudentActivityReportProps {
	studentId: string;
}

enum ChartReportType {
	monthly = 2,
	daily = 3,
}

let echarts = null;

export class StudentActivityReport extends React.Component<StudentActivityReportProps, StudentActivityReportState> {
	dailyChart: any;
	selectedMonth = 0;
	selectedYear = new Date().getUTCFullYear();
	previousReportType = ChartReportType.monthly;
	nameLocation = "end";
	nameGap = 10;
	report: ReportResponseV2 = {};
	inside = false;

	@lazyInject(TYPES.IReportService)
	reportService: IReportService;

	constructor(props) {
		super(props);
		this.state = {
			selectedReportType: ChartReportType.monthly,
			isDataReady: false,
			recentLogs: [],
			libraryChecked: true,
			playlistChecked: true,
		};
	}

	componentDidMount() {
		if (this.isWidthUnder(570)) {
			this.nameLocation = "center";
			this.inside = true;
		}

		Promise.all([
			import("echarts/lib/echarts"),
			import("echarts/lib/chart/line"),
			import("echarts/lib/component/tooltip"),
			import("echarts/lib/component/legend"),
		]).then(([charts]) => {
			echarts = charts;
			const waitDom = () => {
				if (!this.refs.chart) {
					setTimeout(() => {
						waitDom();
					}, 200);
				} else {
					this.initCharts();
					this.initReports();
					this.addChartsResizeEvent();
				}
			};
			waitDom();
		});
	}

	calModeParam = () => {
        let mode: DigitalStudentRepModeParam = 0;
        const {playlistChecked, libraryChecked} = this.state;
        if(playlistChecked && libraryChecked) {
            mode = 0;
        }
        if(playlistChecked && !libraryChecked) {
            mode = 1;
        }
        if(!playlistChecked && libraryChecked) {
            mode = 2;
        }
		return mode;
    }

	getRecentLogsData(chartType, forceQuery=false) {
		const params: any = {};
		params.studentId = this.props.studentId;
		params.chartType = chartType;
		params.month = this.selectedMonth;
		params.year = this.selectedYear;
		params.mode = this.calModeParam();
		if (chartType !== this.previousReportType || forceQuery) {
			this.reportService
				.getRecentLogs(params)
				.then((data) => {
					this.setState({ selectedReportType: chartType, recentLogs: data });
				})
				.catch((er) => {
					this.setState({ selectedReportType: chartType, recentLogs: [] });
				});
		}
		this.previousReportType = chartType;
	}

	componentDidUpdate(prevProps: Readonly<StudentActivityReportProps>, prevState: Readonly<StudentActivityReportState>, snapshot?: any): void {
		if(this.state.libraryChecked !== prevState.libraryChecked || this.state.playlistChecked !== prevState.playlistChecked) {
			this.getRecentLogsData(this.state.selectedReportType, true)
		}
	}

	initCharts() {
		this.dailyChart = echarts.init(ReactDOM.findDOMNode(this.refs.chart));
		const that = this;
		this.dailyChart.on("click", function (params) {
			if (
				that.state.selectedReportType !== ChartReportType.daily &&
				(params.componentType === "xAxis" || params.componentType === "series")
			) {
				const selectedLabel = params.componentType === "xAxis" ? params.value : params.name;
				that.selectedMonth = parseInt(MonthType[selectedLabel.split("-")[0]]);
				that.selectedYear = parseInt(selectedLabel.split("-")[1]);
				that.getRecentLogsData(ChartReportType.daily);
			}
		});
	}

	initReports() {
		this.reportService.getPlayLogs({ mode: this.calModeParam(), studentId: this.props.studentId }).then((data: ReportResponseV2) => {
			this.report = data;
			this.setState({ isDataReady: true, recentLogs: data.recentLogs });
		});
	}

	loadCharts() {
		const chartData = this.state.recentLogs;
		this.dailyChart.setOption(this.formatChartModel(chartData, false));
	}

	isWidthUnder(px) {
		return getWidth() < px;
	}

	addChartsResizeEvent() {
		let timer;
		let isToggle = 0;
		window.addEventListener("resize", () => {
			if (!timer) {
				timer = setTimeout(() => {
					clearTimeout(timer);
					timer = null;
					this.dailyChart.resize();
					isToggle = this.needChangeLocation(isToggle);
				}, 300);
			}
		});
	}

	needChangeLocation(isToggle) {
		const isUnder = this.isWidthUnder(570);
		if (isUnder && isToggle === 0) {
			this.nameLocation = "center";
			this.inside = true;
			this.loadCharts();
			return 1;
		} else if (!isUnder && isToggle === 1) {
			this.nameLocation = "end";
			this.inside = false;
			this.loadCharts();
			return 0;
		}
		return isToggle;
	}

	formatChartModel(logs: any[], isWeekly: boolean) {
		const playTimeName = fmtMsg({ id: SchoolLocale.ReportTime });
		const playTimeMinutes = fmtMsg({ id: SchoolLocale.ReportTimeMinutes });
		const xAxisClickable = this.state.selectedReportType !== ChartReportType.daily ? true : false;
		const playtimes = [],
			playCounts = [],
			xAxisdata = [],
			seriesdata = [],
			self = this;
		logs &&
			logs.forEach((log) => {
				playtimes.push(log.playTime / (60 * 1000));
				xAxisdata.push(log.xAxisLabel);
			});

		const option = {
			tooltip: {
				trigger: "axis", // item
				formatter(data, ticket, callback) {
					let res = "";
					data.forEach((item, index) => {
						let tempValue = item.value;
						if (item.seriesName === playTimeName && item.value > 0) {
							const minute = Math.floor(tempValue);
							const second = Math.round((tempValue * 60) % 60);
							tempValue = `${minute > 9 ? minute : `0${minute}`}:${second > 9 ? second : `0${second}`}`;
						}
						res += item.axisValue + "<br/>" + item.seriesName + "：" + tempValue + "<br/>";
					});
					return res;
				},
			},
			legend: {
				show: true,
				itemGap: 30,
				data: [playTimeName],
			},

			xAxis: [
				{
					type: "category",
					data: xAxisdata,
					silent: false,
					triggerEvent: xAxisClickable,
				},
			],
			yAxis: [
				{
					type: "value",
					name: playTimeName + playTimeMinutes,
					nameLocation: this.nameLocation,
					axisTick: {
						inside: this.inside,
					},
					axisLabel: {
						inside: this.inside,
					},
					nameGap: this.nameGap,
					minInterval: 2,
					splitLine: {
						show: false,
					},
					splitNumber: 3,
				},
			],
			series: [
				{
					name: playTimeName,
					data: playtimes,
					type: "line",
					itemStyle: {
						borderWidth: 4,
						color: "rgb(247, 147, 30)",
					},
					lineStyle: {
						width: 4,
						color: "rgb(0, 181, 156)",
					},
				},
			],
		};
		return option;
	}

	handleTimeCheckboxChange = (fieldName: TimeCheckboxFieldName) => (e) => {
		if(fieldName === TimeCheckboxFieldName.playlistCheckbox) {
			this.setState({ playlistChecked: e.target.checked})
		} else {
		  this.setState({ libraryChecked: e.target.checked})
		}
	  }

	renderChartReport(ref) {
		const {libraryChecked, playlistChecked} = this.state;
		const width = this.state.width;
		const title =
			this.state.selectedReportType === ChartReportType.monthly
				? GLGlobal.intl.formatMessage({ id: SchoolLocale.StudentReportMonthlyViewLast6 })
				: this.state.selectedReportType === ChartReportType.daily && this.selectedMonth > 0
						? GLGlobal.intl.formatMessage(
							{ id: SchoolLocale.StudentReportDailyView },
							{ month: MonthType[this.selectedMonth], year: this.selectedYear }
						)
						: GLGlobal.intl.formatMessage({ id: SchoolLocale.StudentReportDailyLast30 });
		return (
			<>
			<div className="chart" style={{ width: width ? (width > 575 ? width - 400 : width) + "px" : "100%" , padding: '10px'}}>
				<span className="student-activity__chart-report-header">
					<b>{title}</b>
					<Button.Group className="student-activity__timeline">
						<Button
							className={this.state.selectedReportType === ChartReportType.monthly ? "active" : ""}
							size="small"
							onClick={() => {
								this.getRecentLogsData(ChartReportType.monthly);
							}}
						>
							{fmtMsg({ id: SchoolLocale.StudentReportButtonM })}
						</Button>
						<Button
							className={this.state.selectedReportType === ChartReportType.daily ? "active" : ""}
							size="small"
							onClick={() => {
								this.getRecentLogsData(ChartReportType.daily);
							}}
						>
							{fmtMsg({ id: SchoolLocale.StudentReportButtonD })}
						</Button>
					</Button.Group>
				</span>
                <TimeCheckbox libraryChecked={libraryChecked} playlistChecked={playlistChecked} handleOnChange={this.handleTimeCheckboxChange} />
				<div ref={ref}></div>
			</div>
			</>
		);
	}

	render() {
		this.state.isDataReady && this.loadCharts();
		return (
			<>
				<div className="gs-container">{this.renderChartReport("chart")}</div>
			</>
		);
	}
}
