import React from "react";
import { SchoolLocale } from "@app/locales/localeid";
import { FormattedMessage } from "react-intl";
import { Alert } from "antd-min";

export const ClassNotes = ({ notes }) => {
    if (!notes || notes.trim() === "") {
        return null;
    }
    return (
        <div className="class-notes">
            <Alert
                message={
                    <>
                        <b>
                            <FormattedMessage
                                id={SchoolLocale.ClassSchoolNotes}
                            />
                            <span>:</span>
                        </b>
                        <span>&nbsp;{notes.trim()}</span>
                    </>
                }
                type="success"
            ></Alert>
        </div>
    );
};
