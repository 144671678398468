import React, { useRef } from "react";
import { GenericInvitation } from "./generic-invitation";
import "./sidebar.less";
import { ManualRegisteration } from "./manual-registration";
import { SchoolClassModel, ClassesModel } from "@app/service/class";
import { connect, StateType } from "gl-commonui";
import { StudentInvitationType } from "@app/util";

interface SidebarProps {
    total: number;
    model: SchoolClassModel;
    schoolClass: ClassesModel;
    regionId: string;
    onCancel: () => void;
    registrationType: StudentInvitationType;

}

const SidebarComp = (props: SidebarProps) => {

    const isChildModified = useRef(false);

    const setModificationStatus = (modificationStatus) => {
        isChildModified.current = modificationStatus;
    }

    return <>{props.registrationType === StudentInvitationType.PrintInvitation &&
        <div className="rgs-generic"><GenericInvitation passModificationStatus={setModificationStatus} onClose={props.onCancel} /></div>
    }

        {/* {props.registrationType === StudentInvitationType.ManualRegistration && <ManualRegisteration {...props} passModificationStatus={setModificationStatus} />} */}
    </>;
}

export const Sidebar = connect(
    ({ schoolClass: { list, total, model }, school: { current: { regionId } } }: StateType) => ({
        classes: list,
        total,
        model,
        regionId,
    }), {}, ({ state: { classes, students } }) => ({
        schoolClass: classes[0] || {}
    }))(SidebarComp);