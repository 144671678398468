import { ColumnLink, Container, GLIcon, WijmoGrid } from "@app/components";
import { PathConfig } from "@app/config/pathconfig";
import { SchoolLocale } from "@app/locales/localeid";
import { ClassInternalNotes } from "@app/page/cims/component/class-internal-notes";
import { ClassNotes } from "@app/page/cims/component/class-notes";
import { CurriculumType, ISchoolClassService, SchoolClassModel, SubscriptionType } from "@app/service/class/index";
import { CampusModel } from "@app/service/school/campus";
import { StateType } from "@app/states";
import { getClassInfo, getStudentsBy, setFutureAnnualOuter, setIsStudentRegistered } from "@app/states/school/class";
import { setMoveStudentsStatus } from "@app/states/school/class-manager";
import { CommonHelper, CONSTS, fmtMsg, GSSchoolAction } from "@app/util";
import { GSAdminAction, StudentInvitationType, subscriptionTypeUsage } from "@app/util/enum";
import { Drawer, Table, Tooltip } from "antd-min";
import { connect, GLAction, GLGlobal, GLUtil, withRouter } from "gl-commonui";
import React, { Component, useState } from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import { ClassFeatureMenu } from "./components/class-feature-menu";
import { Sidebar } from "./registration/sidebar";
import { ClassHelper, lazyInject, TYPES, DateHelper, AdjustmentDateFormat } from '../../../util/index';
import { IRegionService, RegionModel } from '@app/service/admin/regions';
import { ISchoolLicenseService, UpdateAdjustmentResultCode } from '@app/service/admin/license';
import { isInternalUser, disableFutureLicenseEdit } from "@app/util/func";
import { Tagging } from "@app/components/tagging/tagging";
import { TagEntity } from "@app/service/tag-manager";

const { Grid, Column } = WijmoGrid;

interface StudentsPageProps {
    langLoaded?: string;
    list;
    loading;
    total;
    schoolClass: any;
    subscriptionType;
    subscriptionTypeUsage;
    allowMaterialRequest: boolean;
    allowSchoolEditLicense: boolean;
    classRoles: string[];
    usage: any;
    regionId?: string;
    campus: CampusModel;
    futureAnnualOuter: boolean;
    isStudentRegistered: boolean;
    isInvitationTab: boolean;
    regionEnableCodeLogin: boolean;
    setIsStudentRegistered: (d) => void;
    getStudentsBy: (d) => void;
    getClassInfo: (d) => void;
    setFutureAnnualOuter: (d) => void;
    setMoveStudentsStatus: (d) => void;
    endDate?: Date;
}
class StudentsTable extends Table<SchoolClassModel> { }


interface StudentsPageState {
    moveStudentDisable: boolean;
    currentUnitStartDate?: string;
}

@withRouter
@connect(
    ({
        intl: { langLoaded },
        schoolClass: { futureAnnualOuter, list, students, loading, total, isStudentRegistered, isInvitationTab, model: { endDate } },
        school: {
            current: { allowMaterialRequest, allowSchoolEditLicense, subscriptionType, subscriptionTypeUsage: usage, regionId, regionEnableCodeLogin },
        },
        campus: { model },
    }: StateType) => ({
        langLoaded,
        classes: list,
        list: students,
        loading,
        total,
        allowMaterialRequest,
        allowSchoolEditLicense,
        regionEnableCodeLogin,
        subscriptionType,
        usage,
        regionId,
        futureAnnualOuter,
        isStudentRegistered,
        isInvitationTab,
        endDate
    }),
    {
        getStudentsBy,
        setFutureAnnualOuter,
        getClassInfo,
        setIsStudentRegistered,
        setMoveStudentsStatus
    },
    ({ state: { classes, students } }) => ({
        schoolClass: classes[0] || {},
    })
)


export class StudentsPage extends Component<RouteComponentProps<any> & StudentsPageProps, StudentsPageState> {

    @lazyInject(TYPES.IRegionService)
    regionService: IRegionService;

    @lazyInject(TYPES.ISchoolLicenseService)
    licenseService: ISchoolLicenseService;

    @lazyInject(TYPES.ISchoolClassService)
    schoolClassService: ISchoolClassService;

    isShowModal = false;

    state = {
        moveStudentDisable: false,
        currentUnitStartDate: null
    }
    componentWillReceiveProps(nextProps) {
        if (this.props.langLoaded != nextProps.langLoaded) {
            const {
                getClassInfo,
                match: { params },
            } = this.props;
            getClassInfo(params);
        }

        if (this.props.isStudentRegistered != nextProps.isStudentRegistered && nextProps.isStudentRegistered) {
            const {
                getClassInfo,
                match: { params },
                location,
                setFutureAnnualOuter,
            } = this.props;
            setFutureAnnualOuter(params);
            getClassInfo({ ...params, locationState: location.state });
        }
    }

    componentDidMount() {
        const {
            getClassInfo,
            match: { params },
            location,
            setFutureAnnualOuter,
        } = this.props;
        setFutureAnnualOuter(params);
        getClassInfo({ ...params, locationState: location.state });
        this.settingMoveStudent()
    }
    settingMoveStudent = () => {
        const { match: { params: { schoolId, classId, regionId } } } = this.props;
        regionId && schoolId && classId && this.getInvoiceInfo();
    }


    getRegionInfo = (classInvoiceInfo) => {
        const { match: { params: { regionId, classId } } } = this.props;
        const tasks = [
            this.regionService.getRegionBillingInfo({ id: regionId }),
            this.regionService.getSchoolCycleInvoiceGenerated({ regionId, schoolClassId: classId })
        ];
        Promise.all(tasks).then(res => {
            const [regionInfo, schoolClassInvoiceInfo] = res;
            regionInfo.schoolClassInvoiceInfo = schoolClassInvoiceInfo;
            const { endDate, setMoveStudentsStatus } = this.props;
            const { isLicenseEditDisabled } = disableFutureLicenseEdit(regionInfo, endDate, classInvoiceInfo)
            this.setState({ moveStudentDisable: isLicenseEditDisabled })
            setMoveStudentsStatus(isLicenseEditDisabled)
        });
    }

    getInvoiceInfo = () => {
        const { match: { params: { regionId, schoolId, classId } } } = this.props;
        this.licenseService.getClassInvoiceInfo({ regionId, schoolId, classId })
            .then(classInvoiceInfo => {
                let keys = ["currentCycle", "lastCycle", "nextCycle"];
                for (let k in keys) {
                    classInvoiceInfo[keys[k]].billingCycleStart = classInvoiceInfo[keys[k]].billingCycleStart
                        ? classInvoiceInfo[keys[k]].billingCycleStart.substring(0, 10)
                        : null;
                    classInvoiceInfo[keys[k]].billingCycleEnd = classInvoiceInfo[keys[k]].billingCycleEnd
                        ? classInvoiceInfo[keys[k]].billingCycleEnd.substring(0, 10)
                        : null;
                }
                if (classInvoiceInfo) {
                    classInvoiceInfo.currentLicensePeriodStart = classInvoiceInfo.currentLicensePeriodStart.substring(0, 10);
                    classInvoiceInfo.currentLicensePeriodEnd = classInvoiceInfo.currentLicensePeriodEnd.substring(0, 10);
                }
                if (classInvoiceInfo.currentCycleInvoiceDate) {
                    classInvoiceInfo.currentCycleInvoiceDate = classInvoiceInfo.currentCycleInvoiceDate.substring(0, 10);
                }
                this.getRegionInfo(classInvoiceInfo);
            });
    }

    formatData = (data: any, schoolId: string, schoolClassId: string, currentUnit: number) => {
        if (!data || !data.length) {
            return data;
        }

        if(currentUnit) {
            this.getCurrentUnitStartDate(schoolId, schoolClassId, currentUnit);
        }
        
        if(this.state.currentUnitStartDate) {
            data = this.addAveragePlaylist(data);
            data = this.calculateAverageSessionTime(data);
            data = this.calculateAverageDays(data);
        }

        data = this.convertZeroToNoData(data);
        
        return data;
    }
    
    getCurrentUnitStartDate(schoolId: string, classId: string, unit: number) {
        if(this.state.currentUnitStartDate || !unit) {
            return;
        }
        this.schoolClassService.getUnitStartDate(schoolId, classId, unit)
        .then(res => this.setState({ currentUnitStartDate: res  }));
    }

    convertZeroToNoData(data) {
        return data.map(dataNew => {
            const sessionTime = dataNew.sessionTime || fmtMsg({ id: SchoolLocale.noActivityDataMessage });
            const sessionDays = dataNew.sessionDays || fmtMsg({ id: SchoolLocale.noActivityDataMessage });
            const averagePlaylist = dataNew.averagePlaylist || fmtMsg({ id: SchoolLocale.noActivityDataMessage });
            return { ...dataNew, sessionTime, sessionDays, averagePlaylist };
        })
    }

    calculateAverageSessionTime(data) {
        return data.map(dataNew => {
            var sessionTime = dataNew.sessionTime;                
            
            if(dataNew.sessionTime && dataNew.sessionDays && this.state.currentUnitStartDate.unitStartDate) {
                const sessionTimeInMinutes = sessionTime / 1000 / 60; // getting time from milliseconds to minutes
                const avgSessionTime = (sessionTimeInMinutes / dataNew.sessionDays).toFixed(2);

                sessionTime = avgSessionTime.toString() + " " + fmtMsg({ id: SchoolLocale.averageTimeInMinutes });
            }
            
            return { ...dataNew, sessionTime };
        });
    }
    
    calculateAverageDays(data) {
        const startDate = new Date(this.state.currentUnitStartDate.unitStartDate);
        const todayDate = new Date();
        const dateDiff = this.dateDiffInDays(startDate, todayDate);

        if(dateDiff <= 0) {
            return data;
        }
        
        return data.map(dataNew => {
            if(!startDate || !dataNew.sessionDays){
                return { ...dataNew, sessionDays: 0 };
            }
            const avgSessionDays = (dataNew.sessionDays / (dateDiff / 7)).toFixed(2);
            const sessionDays = avgSessionDays.toString() + " " + fmtMsg({ id: SchoolLocale.averageDaysInDays });
            return { ...dataNew, sessionDays };
        });
    }
    
    addAveragePlaylist(data) {
        const startDate = new Date(this.state.currentUnitStartDate.unitStartDate);
        const todayDate = new Date();
        const dateDiff = this.dateDiffInDays(startDate, todayDate);
        
        if(dateDiff <= 0) {
            return data;
        }

        return data.map(dataNew => {
            if(!startDate || !dataNew.playListCovered){
                return { ...dataNew, averagePlaylist: 0 };
            }
            const averagePlaylist = (dataNew.playListCovered / (dateDiff / 7)).toFixed(2);
            return { ...dataNew, averagePlaylist };
        });
    }

    dateDiffInDays(startDate: Date, endDate: Date) {
        // Discard the time and time-zone information.
        if(!startDate || !endDate){
            return 0;
        }
        
        const utcStartDate = Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());
        const utcEndDate = Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate());
        return Math.floor((utcEndDate - utcStartDate) / CONSTS.MS_PER_DAY);
    }

    render() {
        const {
            schoolClass,
            allowMaterialRequest,
            allowSchoolEditLicense,
            subscriptionType,
            usage,
            total,
            regionId,
            match: { params },
            futureAnnualOuter,
            regionEnableCodeLogin
        } = this.props;
        const { moveStudentDisable } = this.state
        const formattedList = this.formatData(this.props.list, params.schoolId, params.classId, schoolClass.currentUnit)
        const isDigital =
            schoolClass &&
            schoolClass.curriculumType == CurriculumType[CurriculumType.GrapeSEED] &&
            (subscriptionType == SubscriptionType.Digital ||
                (subscriptionType == SubscriptionType.Textbook && usage != subscriptionTypeUsage.Single));
        return (
            <Container fullWidth className="students-page">
                <ClassTitle {...params} name={schoolClass.name} />
                <div className="page-content">
                    <div className="students-page__notes">
                        <ClassNotes notes={schoolClass.schoolNotes} />
                        <ClassInternalNotes notes={schoolClass.notes} />
                    </div>
                    <ClassInfo
                        component={this}
                        schoolClass={schoolClass}
                        params={{ ...params, regionId }}
                        allowMaterialRequest={allowMaterialRequest}
                        allowSchoolEditLicense={allowSchoolEditLicense}
                        enableCodeLogin={regionEnableCodeLogin}
                        subscriptionType={subscriptionType}
                        futureAnnualOuter={futureAnnualOuter}
                        isSingle={usage == subscriptionTypeUsage.Single}
                        moveStudentDisable={moveStudentDisable}
                    />
                    <CprShowed cpred={GLGlobal.isActionValid(GSSchoolAction.StudentList)}>
                        <Grid title={`${fmtMsg({ id: SchoolLocale.StudentStus })} (${total})`} itemsSource={formattedList} pagination={false}>
                            <Column
                                header={fmtMsg({ id: SchoolLocale.StudentEnName })}
                                binding="englishName"
                                render={(text, record, index) => {
                                    const pathStudentReport = regionId
                                        ? GLUtil.pathStringify(PathConfig.StudentReport, {
                                            regionId,
                                            ...this.props.match.params,
                                            studentId: record.id,
                                        })
                                        : null;
                                    return pathStudentReport ? (
                                        <ColumnLink history={this.props.history} url={pathStudentReport}>
                                            {record.englishName}
                                        </ColumnLink>
                                    ) : null;
                                }}
                            />
                            <Column header={fmtMsg({ id: SchoolLocale.StudentName })} binding="name" />
                            <Column header={fmtMsg({ id: SchoolLocale.StudetnBirthday })} binding="birthday" align="right" />
                            {isDigital && (
                                <Column header={fmtMsg({ id: SchoolLocale.StudentPlayListCovered })} binding="playListCovered" />
                            )}
                            {isDigital && (
                                <Column binding="averagePlaylist" align="right"
                                    renderHeader={() => <Tooltip title={fmtMsg({ id: SchoolLocale.averagePlaylistsMessage })}>
                                        {fmtMsg({ id: SchoolLocale.StudentAveragePlaylists })}
                                    </Tooltip>}
                                />
                            )}
                            {isDigital && (
                                <Column binding="sessionDays" align="right"
                                    renderHeader={() => <Tooltip title={fmtMsg({ id: SchoolLocale.averageDaysMessage })}>
                                        {fmtMsg({ id: SchoolLocale.StudentAverageDays })}
                                    </Tooltip>}
                                />
                            )}
                            {isDigital && (
                                <Column binding="sessionTime" align="right"
                                    renderHeader={() => <Tooltip title={fmtMsg({ id: SchoolLocale.averageTimeMessage })}>
                                        {fmtMsg({ id: SchoolLocale.StudentAverageTime })}
                                    </Tooltip>}
                                />
                            )}
                            <Column header={fmtMsg({ id: SchoolLocale.StudentLastLogin })} binding="lastPlayTime" align="right" />
                            <Column align="center" header={fmtMsg({ id: SchoolLocale.StudentActivated })} binding="isActivated" />
                            <Column header={fmtMsg({ id: SchoolLocale.GroupManagerGroupTableTitle })} binding="groupName" align="right" />
                        </Grid>
                    </CprShowed>
                </div>
            </Container>
        );
    }
}

function CprShowed({ cpred, children }) {
    return <>{cpred ? children : null}</>;
}

const ClassTitle = ({ regionId, schoolId, campusId, classId, name }) => {
    const title = name ? name : fmtMsg({ id: SchoolLocale.ClassName });
    const pathClassEdit =
        regionId && schoolId && campusId && classId ? GLUtil.pathStringify(PathConfig.ClassEdit, { regionId, schoolId, campusId, classId }) : null;
    const hasTagViewPermission = GLGlobal.isActionValid(GSAdminAction.SchoolTagsView);
    const hasTagManagerPermission = GLGlobal.isActionValid(GSAdminAction.SchoolTagsManage);

    return (
        <div className="edit-list-title">
            <h1 title={title}>{title}</h1>
            {pathClassEdit && (
                <GLAction action={GSSchoolAction.EditClass}>
                    <Link to={pathClassEdit}>
                        <GLIcon type="edit" labelId={SchoolLocale.IconEdit} />
                    </Link>
                </GLAction>
            )}
            <div className="tag-row">
                    {hasTagViewPermission && schoolId && (
                                    <Tagging
                                        entityId={classId}
                                        regionId={regionId}
                                        entity={TagEntity.SchoolClass}
                                        isViewOnly={!hasTagManagerPermission}
                                        popoverPlacement="bottomLeft"
                                    />
                                    )}
                </div>
        </div>
    );
};
const LicenseCnt = ({
    params,
    curriculumType,
    studentCount,
    textbookLicense,
    digitalLicense,
    subscriptionType,
    classDisabled,
    isSingle,
    allowSchoolEditLicense,
    children,
}: any) => {
    if (curriculumType) {
        const isDigital = subscriptionType === SubscriptionType.Digital;
        const isTextbook = subscriptionType === SubscriptionType.Textbook;
        const isLittleSeed = curriculumType === CurriculumType[CurriculumType.LittleSEED];
        return (
            <>
                <TextLabel labelId={SchoolLocale.ClassCnt} oper={children}>
                    <TextLink
                        text={isLittleSeed ? textbookLicense : isDigital ? digitalLicense : textbookLicense}
                        path={PathConfig.ClassLicense}
                        route={params}
                        hasLink={
                            !classDisabled && GLGlobal.isActionValid(GSSchoolAction.EditLicenseEntry)
                            // && ClassHelper.canEditLicense(classRoles, allowSchoolEditLicense)
                        }
                    />
                </TextLabel>
                {/* <TextLabel labelId={isDigital ? SchoolLocale.ClassLicence : SchoolLocale.ClassDigital} oper={isTextbook ? children : null}>
                    <TextLink
                        text={isDigital ? textbookLicense : digitalLicense}
                        path={PathConfig.ClassLicense}
                        route={params}
                        hasLink={false}
                    // hasLink={!classDisabled && isTextbook && GLGlobal.isActionValid(GSSchoolAction.EditLicense)}
                    />
                </TextLabel> */}
            </>
        );
    }
    // else if (licenseType === LicenseType[LicenseType.LittleSEED]) {
    //     const isDigital = subscriptionType === SubscriptionType.Digital;
    //     const isTextbook = subscriptionType === SubscriptionType.Textbook;
    //     return (
    //         <>
    //             <TextLabel labelId={SchoolLocale.ClassCnt}>
    //                 <TextLink
    //                     text={studentCount}
    //                     path={PathConfig.ClassLicense}
    //                     route={params}
    //                     hasLink={!classDisabled
    //                         && GLGlobal.isActionValid(GSSchoolAction.EditLicense)
    //                         && ClassHelper.canEditLicense(classRoles, allowSchoolEditLicense)
    //                     }
    //                 />
    //             </TextLabel>
    //             <TextLabel labelId={SchoolLocale.ClassLicence} oper={children}>
    //                 <TextLink
    //                     text={textbookLicense}
    //                     path={PathConfig.ClassLicense}
    //                     route={params}
    //                     hasLink={false}
    //                 // hasLink={!classDisabled && isTextbook && GLGlobal.isActionValid(GSSchoolAction.EditLicense)}
    //                 />
    //             </TextLabel>
    //         </>
    //     )
    // }
    return null;
};

const ClassInfo = ({
    component,
    schoolClass: { currentUnit, teacher, subTeacher, disabled, substituteTeacherIds, ...rest },
    params,
    allowMaterialRequest,
    allowSchoolEditLicense,
    subscriptionType: type,
    isSingle,
    futureAnnualOuter,
    moveStudentDisable,
    enableCodeLogin
}) => {
    const substituteTeacherSuffixCount = substituteTeacherIds && substituteTeacherIds.length > 1 ? substituteTeacherIds.length - 1 : null;
    const pathClassCart = params.regionId ? GLUtil.pathStringify(PathConfig.ClassCart, params) : null;
    const [drawerVisible, setDrawerVisible] = useState(false);
    const pathInvitationHistory =
        params.regionId && params.schoolId && params.campusId && params.classId ? GLUtil.pathStringify(PathConfig.ClassInvitations, params) : null;
    const pathLicence = params.regionId && params.schoolId && params.campusId && params.classId ? GLUtil.pathStringify(PathConfig.ClassLicense, params) : null;
    const pathManualStudentRegistration = params.regionId && params.schoolId && params.campusId && params.classId ? GLUtil.pathStringify(PathConfig.RegisterStudents, params) : null;
    const pathLicenceHistory = params.regionId && params.schoolId && params.campusId && params.classId ? GLUtil.pathStringify(PathConfig.ClassLicenseHistory, params) : null;
    const pathUnitPlan = params.regionId && params.schoolId && params.campusId && params.classId ? GLUtil.pathStringify(PathConfig.ClassUnitPlan, params) : null;
    const pathGroupManager = params.regionId && params.schoolId && params.campusId && params.classId ? GLUtil.pathStringify(PathConfig.ClassGroupManager, params) : null;
    const pathMoveStudent = params.regionId && params.schoolId && params.campusId && params.classId ? GLUtil.pathStringify(PathConfig.MovePromoteStudentsFromClass, params) : null;
    const pathManageStudentLogin = params.regionId && params.schoolId && params.campusId && params.classId ? GLUtil.pathStringify(PathConfig.SchoolManageStudentLogin, params) : null;
    const pathEdit = params.regionId && params.schoolId && params.campusId && params.classId ? GLUtil.pathStringify(PathConfig.ClassEdit, params) : null;
    const pathTeacher = params.regionId && params.schoolId && params.campusId && params.classId ? GLUtil.pathStringify(PathConfig.Teachers, params) : null;

    const [registrationType, setRegistrationType] = useState(StudentInvitationType.PrintInvitation);
    const drawerWidth = CommonHelper.isMobile() ? "80%" : "50%";

    const onDrawerClose = () => {
        if (component.props.isInvitationTab) {
            component.props.setIsStudentRegistered({ isStudentRegistered: true });
        }
        setDrawerVisible(false);
    };

    const setDrawerVisibility = (visibiltyStatus, type) => {
        setDrawerVisible(visibiltyStatus);
        setRegistrationType(type);
    };

    return (
        <div className="class-info">
            <div className="text-labels">
                <TextLabel className="label-left" labelId={SchoolLocale.StudentTeacher}>
                    <TeacherLabel text={teacher} route={params} classDisabled={disabled} />
                </TextLabel>
                <TextLabel className="label-left" labelId={SchoolLocale.StudentSubTeacher}>
                    <TeacherLabel text={subTeacher} route={params} classDisabled={disabled} suffixCount={substituteTeacherSuffixCount} />
                </TextLabel>
            </div>

            <div className="text-labels">
                <LicenseCnt
                    {...rest}
                    subscriptionType={type}
                    params={params}
                    classDisabled={disabled}
                    allowSchoolEditLicense={allowSchoolEditLicense}
                ></LicenseCnt>
                <TextLabel labelId={SchoolLocale.ClassUnit}>
                    <TextLink
                        text={currentUnit || fmtMsg({ id: SchoolLocale.TextNone })}
                        path={PathConfig.ClassUnitPlan}
                        route={params}
                        hasLink={GLGlobal.isActionValid(GSSchoolAction.EditUnitPlan)}
                    />
                </TextLabel>
            </div>

            <div className="text-labels">
                <ClassFeatureMenu
                    {...{ allowMaterialRequest, disabled, pathClassCart, futureAnnualOuter, pathInvitationHistory, setDrawerVisibility, pathLicence, pathLicenceHistory, pathUnitPlan, pathGroupManager, pathManualStudentRegistration, moveStudentDisable, pathMoveStudent, pathManageStudentLogin, enableCodeLogin, pathEdit, pathTeacher }}
                ></ClassFeatureMenu>
                <AllChildrenInvited {...rest}>
                    <GLAction action={GSSchoolAction.InviteStudents}>
                        <Drawer
                            destroyOnClose
                            visible={drawerVisible}
                            width={drawerWidth}
                            maskClosable={false}
                            onClose={() => onDrawerClose()}
                            title={fmtMsg({
                                id:
                                    registrationType === StudentInvitationType.ManualRegistration
                                        ? SchoolLocale.StudentRegistrationManualSecHeading
                                        : SchoolLocale.StudentRegistrationGenericSecHeading,
                            })}
                        >
                            <Sidebar registrationType={registrationType} onCancel={() => onDrawerClose()} />
                        </Drawer>
                    </GLAction>
                </AllChildrenInvited>
            </div>
        </div>
    );
};
const AllChildrenInvited = ({ licenseType, children }: any) => {
    return children;
};
const TextLabel = ({ labelId, className = "", oper = null, children }) => {
    return (
        <div className="text-label">
            <div className={`label ${className}`}>
                <span>{lowerCase(fmtMsg({ id: labelId }))}</span>
                <span className="colon">:</span>
                <span className="text">{children}</span>
            </div>

            {oper}
        </div>
    );
};
const TeacherLabel = ({ text, route, classDisabled, suffixCount = null }) => {
    const pathTeachers = route.regionId ? GLUtil.pathStringify(PathConfig.Teachers, route) : null;
    return (
        <>
            <span title={text}>{text || fmtMsg({ id: SchoolLocale.TextNone })}</span>
            {suffixCount && <span>&nbsp;{`+ ${suffixCount}`}</span>}
            {pathTeachers && (
                <GLAction action={GSSchoolAction.EditTeacher}>
                    <Link to={pathTeachers}>
                        <GLIcon type="edit" labelId={SchoolLocale.StudetnInvite} />
                    </Link>
                </GLAction>
            )}
        </>
    );
};
const TextLink = ({ text, route, path, hasLink = true }) => {
    if (hasLink) {
        const pathTo = route.regionId ? GLUtil.pathStringify(path, route) : "/";
        return (
            <Link className={"link"} to={pathTo}>
                {text}
            </Link>
        );
    } else {
        return text;
    }
};
function lowerCase(text: string) {
    return text.toLowerCase().replace(/\b\w/, (c) => c.toUpperCase());
}
