import React, { Component } from "react";
import { Row, Col, Typography, Button, Form, Icon } from "antd-min";
import DatePicker from "react-datepicker";
import MaskedTextInput from "react-text-mask";
import moment from "moment";
import {GLGlobal, LanguageDateFormat, GLForm, MatIcon} from "gl-commonui";
import { StudentProgressModel } from "@app/service/school/report";
import { fmtMsg } from "@app/util/func";
import { SchoolLocale } from "@app/locales/localeid";
import {langsDateMasks} from "@app/util";

export interface CalendarHeaderProps {
	onClickBack: (e: any) => void;
	onClickForward: (e: any) => void;
	title: string;
	disableForwardButton?: boolean;
	lastThirtyDayData: StudentProgressModel[];
	selectedStartDate: moment.Moment;
	handleSelectedStartDateChange: (date: any) => void;
	disablePopperFlip: Boolean;
}

export interface CalendarHeaderState {
	disableForwardButton: boolean;
	mask: any[];
	currentLanguage: string;
}

export class CalendarHeader extends Component<CalendarHeaderProps, CalendarHeaderState> {
	constructor(props: CalendarHeaderProps) {
		super(props);
		this.state = {
			disableForwardButton: props.disableForwardButton,
			currentLanguage: GLGlobal.intl.locale,
			mask: langsDateMasks[LanguageDateFormat[GLGlobal.intl.locale]],
		};
	}

	selectDatePickerRef: any;

	// Propeties that need to be added to DatePicker component to prevent popper flip above.
	disablePopperFlip = this.props.disablePopperFlip
		? {
				popperPlacement: "bottom-start",
				popperModifiers: {
					flip: {
						behavior: ["bottom"], // don't allow it to flip to be above
					},
					preventOverflow: {
						enabled: false, // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
					},
					hide: {
						enabled: false, // turn off since needs preventOverflow to be enabled
					},
				},
		  }
		: {};

	static getDerivedStateFromProps(props: CalendarHeaderProps, state: CalendarHeaderState) {
		if (props.selectedStartDate && state.currentLanguage !== GLGlobal.intl.locale) {
			const date = moment(props.selectedStartDate);
			return {
				selectedStartDate: date,
				mask: langsDateMasks[LanguageDateFormat[GLGlobal.intl.locale]],
				currentLanguage: GLGlobal.intl.locale,
			};
		}
		if (props.disableForwardButton !== state.disableForwardButton) {
			return { disableForwardButton: props.disableForwardButton };
		}
		return {};
	}

	showSelectDatePicker = (): void => {
		this.selectDatePickerRef.setOpen(true);
	};

	render() {
		const dateFormat: string = LanguageDateFormat[GLGlobal.intl.locale].toLowerCase().replace("mm", "MM");
		const placeholder = LanguageDateFormat[GLGlobal.intl.locale];
		return (
			<Row align="top" className="cal__calendar-header">
				<div className="cal__date-row">
					<div className="cal__naviagation-buttons">
						<Col span={3} className="cal__back-button">
                            <Button type="primary" onClick={this.props.onClickBack}>
                                <MatIcon type="arrow_left" />
                            </Button>
						</Col>
					</div>
					<div>
						<Form.Item
							label={fmtMsg({ id: SchoolLocale.ReportStartDate })}
							className="cal__datepicker-label"
						>
							<DatePicker
								{...this.disablePopperFlip}
								locale={GLGlobal.intl.locale}
								dateFormat={dateFormat}
								selected={this.props.selectedStartDate.toDate()}
								onChange={this.props.handleSelectedStartDateChange}
								disableKeyboardNavigation
								customInput={
									<MaskedTextInput
										type="text"
										mask={this.state.mask}
										className="ant-input ant-calendar-picker-input"
									/>
								}
								showMonthDropdown
								showYearDropdown
								dropdownMode="select"
								maxDate={new Date()}
								placeholderText={placeholder}
								ref={(c) => (this.selectDatePickerRef = c)}
							></DatePicker>
							<Icon
								onClick={this.showSelectDatePicker}
								type="calendar"
								className="anticon anticon-calendar ant-calendar-picker-icon"
							/>
						</Form.Item>
					</div>
					<div className="cal__naviagation-buttons">
						<Col className="cal__forward-button">
							<Button
								type="primary"
								onClick={this.props.onClickForward}
								disabled={this.state.disableForwardButton}
                            >
                                <MatIcon type="arrow_right" />
                            </Button>
						</Col>
					</div>
				</div>
			</Row>
		);
	}
}
