import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { FormattedMessage } from 'react-intl';
import { Dropdown, Menu, Icon, Badge } from 'antd-min';
import { alignPop, GLAction, GLGlobal, connect, StateType, RoleName } from 'gl-commonui';
import { IconFont } from '@app/util/consts';
import { GLIcon } from '@app/components';
import {
	GSSchoolAction, GSAdminAction, fmtMsg, StudentRegistrationType, StudentInvitationType, isInternalUser,
	registerStudentVisibilityCondition, isRegisterManualDisable, hasConnectLicense
} from '@app/util';
import { SchoolLocale, GSAdminLocale } from '@app/locales/localeid';
import { SchoolClassModel, ClassesModel } from '@app/service/class';
import { isNull } from 'util';
import { InvitationTemplateIcon } from '@app/components/svgicon';
import './class-feature-menu.less';

interface ClassFeatureMenuProps {
	allowMaterialRequest: boolean;
	disabled: boolean;
	pathClassCart: any;
	futureAnnualOuter: boolean;
	pathInvitationHistory: any;
	pathLicence: any;
	pathLicenceHistory: any;
	pathUnitPlan: any;
	pathGroupManager: any;
	pathManageStudentLogin: any;
	setDrawerVisibility: (e, type) => void;
	model: SchoolClassModel;
	pathManualStudentRegistration: any;
	moveStudentDisable: boolean;
	pathMoveStudent: any;
	enableCodeLogin: boolean;
	annualPrepComplete: boolean;
	hasGSConnectPermission: boolean;
	pathEdit: any;
	pathTeacher: any;
}

const FeatureMenu = (props: ClassFeatureMenuProps) => {
	const [menuVisible, setMenuVisible] = useState(false);
	const hasClassCartMenu = props.allowMaterialRequest && !props.disabled && props.pathClassCart && !props.futureAnnualOuter && GLGlobal.isActionValid(GSSchoolAction.Cart);
	const hasInvitationHistory = !props.disabled && GLGlobal.isActionValid(GSAdminAction.ListPortalInvitation);

	const printInvitationVisibilityCondition = () => {

		if (isInternalUser() || props.model.studentRegistrationTypeId === StudentRegistrationType.Both || props.model.studentRegistrationTypeId == StudentRegistrationType.Generic) {
			return true;
		}

		return false;
	}

	const hasPrintInvitaion = !props.disabled && GLGlobal.isActionValid(GSSchoolAction.InviteStudents) && printInvitationVisibilityCondition();
	const hasRegisterStudent = !props.disabled && GLGlobal.isActionValid(GSSchoolAction.InviteStudents) && registerStudentVisibilityCondition(props.model.studentRegistrationTypeId);
	const hasLicence = !props.disabled && (GLGlobal.isActionValid(GSSchoolAction.EditLicenseEntry) || isInternalUser());
	const hasLicenceHistory = GLGlobal.isActionValid(GSSchoolAction.EditLicenseEntry) || isInternalUser();
	const hasUnitPlan = GLGlobal.isActionValid(GSSchoolAction.EditUnitPlan);
	const hasGroupManager = !props.disabled && GLGlobal.isActionValid(GSSchoolAction.EditClass) && (hasConnectLicense(props.model.licenseType));
	const now = new Date();
	const compareStartDate = new Date(props.model.startDate);
	const hasMoveStudent = !props.disabled && GLGlobal.isActionValid(GSSchoolAction.MovePromoteStudents) && compareStartDate <= now ? !props.moveStudentDisable : false;
	const enableCodeLogin = !props.disabled && props.enableCodeLogin;
	const hasFirstDivider = hasClassCartMenu && (hasPrintInvitaion || hasRegisterStudent);
	const hasSecondDivider = hasFirstDivider && hasInvitationHistory;
	const hasThirdDivider = hasSecondDivider && (hasLicence || hasUnitPlan || hasLicenceHistory);
	const hasFourDivider = hasThirdDivider && (hasGroupManager || hasMoveStudent || enableCodeLogin);
	const isMovePromoteDisabled = props.annualPrepComplete;
	const hasEditMenu = GLGlobal.isActionValid(GSSchoolAction.EditClass);
	const hasTeacherMenu = hasEditMenu && GLGlobal.isActionValid(GSSchoolAction.EditTeacher);

	const isPrintInvitationDisable = () => {
		if (!isInternalUser() && props.model.studentRegistrationTypeId === StudentRegistrationType.Manual) {
			return true;
		}

		return false;
	};

	const onSetDrawer = (type) => {
		setMenuVisible(false);
		props.setDrawerVisibility(true, type);
	}

	const menus = (
		<Menu className="class-feature-menu">
			{hasEditMenu &&
				<Menu.Item>
					<Link to={props.pathEdit}>
						<Icon type="edit" />
						<FormattedMessage id={SchoolLocale.EditClass} />
					</Link>
				</Menu.Item>}
			{hasEditMenu && <Menu.Divider />}
			{hasTeacherMenu &&
				<Menu.Item>
					<Link to={props.pathTeacher}>
						<Icon type="team" />
						<FormattedMessage id={SchoolLocale.ClassTeacher} />
					</Link>
				</Menu.Item>}
			{hasTeacherMenu && <Menu.Divider />}
			{hasClassCartMenu &&
				<Menu.Item>
					<Link to={props.pathClassCart}>
						<GLIcon type="shopping-cart" />
						<FormattedMessage id={SchoolLocale.ClassMaterialRequest} />
					</Link>
				</Menu.Item>}
			{hasFirstDivider && <Menu.Divider />}
			{hasPrintInvitaion &&
				<Menu.Item disabled={isPrintInvitationDisable()} onClick={() => onSetDrawer(StudentInvitationType.PrintInvitation)}>
					<GLIcon type="printer" />
					<span>{fmtMsg({ id: SchoolLocale.StudentRegistrationPrintText })}</span>
				</Menu.Item>
			}
			{hasRegisterStudent &&
				<Menu.Item disabled={isRegisterManualDisable(props.model.studentRegistrationTypeId)}>
					{isRegisterManualDisable(props.model.studentRegistrationTypeId) && <><GLIcon type="user" />
						<span>{fmtMsg({ id: SchoolLocale.StudentRegistrationRegisterLinkText })}</span></>}
					{!isRegisterManualDisable(props.model.studentRegistrationTypeId) && <Link to={props.pathManualStudentRegistration}>
						<GLIcon type="user" />
						<FormattedMessage id={SchoolLocale.StudentRegistrationRegisterLinkText} />
					</Link>}
				</Menu.Item>
			}
			{hasSecondDivider && <Menu.Divider />}
			{hasInvitationHistory &&
				<Menu.Item>
					<Link to={props.pathInvitationHistory}>
						<InvitationTemplateIcon />
						<FormattedMessage id={SchoolLocale.StudentRegistrationInvitationLinkText} />
					</Link>
				</Menu.Item>
			}
			{hasThirdDivider && <Menu.Divider />}
			{hasLicence &&
				<Menu.Item>
					<Link to={props.pathLicence}>
						<IconFont type="glslicence" />
						<FormattedMessage id={SchoolLocale.BreadMenuLicense} />
					</Link>
				</Menu.Item>
			}
			{
				hasLicenceHistory &&
				<Menu.Item>
					<Link to={props.pathLicenceHistory}>
						<IconFont type="glslicence-history" />
						<FormattedMessage id={SchoolLocale.BreadMenuLicenseHistory} />
					</Link>
				</Menu.Item>
			}
			{
				hasUnitPlan &&
				<Menu.Item>
					<Link to={props.pathUnitPlan}>
						<IconFont type="glsunitplan" />
						<FormattedMessage id={SchoolLocale.BreadMenuUnitPlan} />
					</Link>
				</Menu.Item>
			}
			{hasFourDivider && <Menu.Divider />}
			{
				hasGroupManager &&
				<Menu.Item>
					<Link to={props.pathGroupManager}>
						<Icon type="unordered-list" />
						<FormattedMessage id={SchoolLocale.BreadMenuGroupManager} />
					</Link>
				</Menu.Item>
			}
			{
				hasMoveStudent &&
				<Menu.Item disabled={isMovePromoteDisabled} className={isMovePromoteDisabled && 'disabled-menuitem'}>
					<Link to={props.pathMoveStudent}>
						<Icon type="team" />
						<FormattedMessage id={SchoolLocale.BreadTextMoveStudents} />
					</Link>
				</Menu.Item>
			}
			{
				enableCodeLogin &&
				<Menu.Item>
					<Link to={props.pathManageStudentLogin}>
						<IconFont type="glsunitplan" />
						<FormattedMessage id={SchoolLocale.BreadMenuManageStudentLogin} />
					</Link>
				</Menu.Item>
			}
		</Menu>
	);
	return (
		<div className='class-feature-menu-icon'>
			<Dropdown overlay={menus} trigger={['click']} onVisibleChange={setMenuVisible} {...alignPop()}>
				<span><Icon type="unordered-list" /> {fmtMsg({ id: SchoolLocale.ClassManage })} {menuVisible ? <Icon type="up" /> : <Icon type="down" />}</span>
			</Dropdown>
		</div>
	)
}

export const ClassFeatureMenu = connect(
	({ schoolClass: { model, hasGSConnectPermission }, school: { current : { annualPrepComplete }} }: StateType) => ({
		model,
		annualPrepComplete,
		hasGSConnectPermission
	}))(FeatureMenu);
