import React, { Component } from "react";
import { Row } from "antd-min";
import { fmtMsg } from "@app/util/func";
import { SchoolLocale } from "@app/locales/localeid";
import ReactSpeedometer from "react-d3-speedometer";
import {StudentProgressModel} from "@app/service/school/report";

export interface SpeedometerProps {
	lastThirtyDayData: StudentProgressModel[];
}

export class Speedometer extends Component<SpeedometerProps> {
	constructor(props: SpeedometerProps) {
		super(props);
	}

	render() {
		const avg = ((this.props.lastThirtyDayData.length / 30) * 7).toFixed(1);

		return (
			<Row type="flex" justify="center">
				<ReactSpeedometer
					width={300}
					height={200}
					maxValue={4}
					value={ Number(avg) > 4 ? 4 : Number(avg) }
					startColor={"#75f09c"}
					endColor={"#0d732a"}
					needleColor="#00591c"
					segments={4}
					textColor="#00591c"
					currentValueText={fmtMsg({ id: SchoolLocale.PlaylistsPerWeek }) + `: ${Number(avg)}`}
					paddingVertical={10}
				/>
			</Row>
		);
	}
}
